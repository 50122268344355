import { Capacitor } from '@capacitor/core';
import axios, { AxiosResponse } from 'axios';

import { isDev, isProd } from 'utils/env.utils';

import { EnvTenant } from 'types/env.types';

export async function fetchTenantConfig() {
  // override for dev/localhost
  const isWeb = Capacitor.getPlatform() === 'web';

  const hostName = isDev ? 'delighter.recneps.app' : window.location.host;
  const tenantBase = isProd ? 'delighter' : 'delighter-stag';
  const tenantConfigUrl = `https://${tenantBase}-api.spencer.co/api/public/tenantconfig`;
  const url = isWeb ? tenantConfigUrl : '/app/tenant-config.json';

  const headers = isWeb
    ? {
        'Content-Type': 'application/json',
        'X-Tenant-Host': hostName,
        'Cache-Control': 'no-cache',
      }
    : undefined;

  const { data }: AxiosResponse<EnvTenant> = await axios.get(url, {
    headers,
  });

  return data;
}

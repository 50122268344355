import { ThemeOptions } from '@mui/material';

export const mixins: ThemeOptions['mixins'] = {
  content: {
    maxWidth: '1184px',
  },
  sideBar: {
    width: '256px',
  },
  header: {
    height: '64px',
  },
  footer: {
    height: '56px',
  },
  zIndex: {
    reactionsPopup: 100,
    newsDetailSidebar: 100,
    footer: 51,
    header: 51,
    cardTag: 5,
    spotlightCard: 4,
  },
  brightness: {
    medium: 'inset 0 0 100px 100px rgba(255, 255, 255, 0.1)',
    light: 'inset 0 0 100px 100px rgba(255, 255, 255, 0.2)',
  },
};
